import React from 'react'

export default function WordCount({ count, type }) {
    return (
        <div className="flex items-center justify-center rounded-md text-secondary-text border border-solid bg-secondary py-2 px-8 text-sm w-[25%] mr-3">
            <p>{count}</p>
            <p className="ml-1">{type === 'words' ? 'mots' : 'caractères'}</p>
        </div>
    )
}
