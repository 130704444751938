import { useCurrentEditor } from '@tiptap/react'
import WordCount from '../WordCount/WordCount'
import { useRPContent } from '../../Context/rpContent'
import { useEffect } from 'react'

export default function Counter() {
    const {
        setRPContent,
        characterCount,
        setCharacterCount,
        wordCount,
        setWordCount,
    } = useRPContent()
    const { editor } = useCurrentEditor()
    const text = JSON.stringify(editor.getText())
    const HTML = JSON.stringify(editor.getHTML(), null, 2)
    let wordCountVar
    if (text === '""') {
        wordCountVar = 0
    } else {
        wordCountVar = text
            .split(' ')
            .filter((item) => item !== ' ' && item !== '"').length
    }
    useEffect(() => {
        setRPContent(HTML)
        setCharacterCount(
            text.split('').filter((item) => item !== ' ' && item !== '"').length
        )
        setWordCount(wordCountVar)
    }, [
        HTML,
        editor,
        setRPContent,
        characterCount,
        setCharacterCount,
        wordCount,
        setWordCount,
        text,
        wordCountVar,
    ])
    return (
        <div className="flex mb-4 justify-start">
            <WordCount type="characters" count={characterCount} />
            <WordCount type="words" count={wordCount} />
        </div>
    )
}
