export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max)
}

export const removeSpaces = (text = '') => {
    const backslashRemoved = text.replaceAll('\\"', '"')
    return backslashRemoved.substring(1, backslashRemoved.length - 1)
}

export const countRPPerStatus = (countByStatus) => {
    const upToDateCount = Array.from(countByStatus).filter(
        (item) => item.status === 'À jour'
    ).length
    const notUpToDateCount = Array.from(countByStatus).filter(
        (item) => item.status === 'Pas à jour'
    ).length
    return {
        upToDate: upToDateCount,
        notUpToDate: notUpToDateCount,
    }
}

export const countRPPerCharacter = (allRPs) => {
    const formattedRPs = Array.from(allRPs).map((item) =>
        item.character === '' ? 'Aucun personnage' : item.character
    )
    const uniqueCharacter = [...new Set(formattedRPs)]
    const countPerCharacter = uniqueCharacter.map((character) => [
        character,
        formattedRPs.filter((char) => char === character)?.length,
    ])
    return [['Personnages', 'Count'], ...countPerCharacter]
}

export const countRPPerForum = (allRPs) => {
    const formattedRPs = Array.from(allRPs).map(
        (item) => item.link.split('//')[1] || null
    )
    const reformattedRPs = Array.from(formattedRPs).map(
        (item) => item?.split('.')[0] || 'Aucun forum'
    )
    const allForums = Array.from(reformattedRPs).map((item) =>
        !item ? 'Aucun forum' : item
    )
    const uniqueForum = [...new Set(allForums)]
    const countPerForum = uniqueForum.map((forum) => [
        forum,
        reformattedRPs.filter((fofo) => fofo === forum)?.length,
    ])
    return [['Forums', 'Count'], ...countPerForum]
}

export const isLightColor = (color) => {
    let r, g, b

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        const rgb = color.match(
            /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        )

        r = rgb[1]
        g = rgb[2]
        b = rgb[3]
    } else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        const hexColor = +(
            '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
        )

        r = hexColor >> 16
        g = (hexColor >> 8) & 255
        b = hexColor & 255
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    // > 127.5 is 'light', <= 127.5 is 'dark'

    return hsp > 127.5
}

export const makeCall = async (url, options, auth) => {
    const res = await fetch(url, options)
    if (!res.ok) {
        if (res.status === 401) {
            if (auth) {
                throw new Error('Unauthorized')
            } else {
                window.location.href = '/'
                return
            }
        } else {
            throw new Error('Error', res.status)
        }
    }
    const responseBody = await res.json()
    return { data: responseBody, headers: res.headers }
}

// TRACKER

export const getRPs = async () => {
    return await makeCall('https://airpeger-api.vercel.app/rp/getrps', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const checkRPs = async (rpLink, characters) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/checkrp', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ url: rpLink, characters: characters }),
    })
}

export const addRP = async (
    newRPLink,
    newRPName,
    newRPCharacter,
    newLastDate,
    isClosed,
    isManual,
    manualStatus
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/trackrp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            rpLink: newRPLink,
            rpName: newRPName,
            rpCharacter: newRPCharacter,
            lastDate: newLastDate,
            isClosed: isClosed,
            isManual: isManual,
            manualStatus: manualStatus,
        }),
    })
}

export const addRPToOpen = async (
    newToOpenName,
    newToOpenCharacter,
    newToOpenDescription
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/rptoopen', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            name: newToOpenName,
            character: newToOpenCharacter,
            description: newToOpenDescription,
        }),
    })
}

export const updateRP = async (
    rpLink,
    newRPName,
    newRPCharacter,
    newLastDate,
    isClosed,
    isManual,
    manualStatus
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/updaterp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            rpLink: rpLink,
            rpName: newRPName,
            rpCharacter: newRPCharacter,
            lastDate: newLastDate,
            isClosed: isClosed,
            isManual: isManual,
            manualStatus: manualStatus,
        }),
    })
}

export const deleteRP = async (RPToDelete) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/deleterp', {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ rpLink: RPToDelete }),
    })
}

export const deleteToOpenRP = async (RPToDelete) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/deletetoopenrp', {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ rpName: RPToDelete }),
    })
}

// CHARACTERS

export const getCharacters = async () => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/getcharacters',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
        }
    )
}

export const addCharacter = async ({
    newCharacterName,
    newCharacterAge,
    newCharacterRpColor,
    newCharacterOccupation,
    newCharacterDescription,
    newCharacterPicture,
}) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/addcharacter',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({
                name: newCharacterName,
                occupation: newCharacterOccupation,
                age: newCharacterAge,
                rpColor: newCharacterRpColor,
                description: newCharacterDescription,
                picture: newCharacterPicture,
            }),
        }
    )
}

export const updateCharacter = async ({
    name,
    description,
    picture,
    age,
    occupation,
    rpColor,
}) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updatecharacter',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({
                name,
                description,
                picture,
                age,
                occupation,
                rpColor,
            }),
        }
    )
}

export const deleteCharacter = async (characterName) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/deletecharacter',
        {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ characterName: characterName }),
        }
    )
}

// AUTH

export const login = async (pseudo, password) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/login',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ pseudo: pseudo, password: password }),
        },
        true
    )
}

export const signup = async (pseudo, password, email) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/signup',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({
                pseudo: pseudo,
                password: password,
                email: email,
            }),
        },
        true
    )
}

export const requestPassword = async (email) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/requestpassword',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ email: email }),
        }
    )
}

export const editPassword = async (password, token) => {
    return await makeCall('https://airpeger-api.vercel.app/auth/editpassword', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: password, token: token }),
    })
}

export const getAuthToken = () =>
    `Bearer ${localStorage.getItem('airpeger-token')}`

export const getUser = async () => {
    if (localStorage.getItem('airpeger-token')) {
        function getPayload(jwt) {
            return JSON.parse(atob(jwt.split('.')[1]))
        }
        const payload = getPayload(localStorage.getItem('airpeger-token'))
        const expiration = new Date(payload.exp * 1000)
        const now = new Date()

        if (expiration < now) {
            return false
        } else {
            return await makeCall(
                'https://airpeger-api.vercel.app/account/getuserinfo',
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: getAuthToken(),
                    },
                }
            )
        }
    } else {
        return false
    }
}

// STATS

export const getStats = async () => {
    return await makeCall('https://airpeger-api.vercel.app/rp/getstats', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const getGlobalStats = async () => {
    return await makeCall(
        'https://airpeger-api.vercel.app/stats/getuserstats',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
    )
}

// ACCOUNT

export const updateUser = async (password, picture) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updateuserinfo',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ password: password, picture: picture }),
        }
    )
}

// DRAFTS

export const getDraft = async () => {
    return await makeCall('https://airpeger-api.vercel.app/account/getdraft', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const updateDraft = async (draft) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updatedraft',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ draft: draft }),
        }
    )
}

// RP

export const postRP = async (characterName, wordCount, characterCount) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/publishrp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            characterName: characterName,
            wordCount: wordCount,
            characterCount: characterCount,
        }),
    })
}
