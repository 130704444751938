import { useState, useEffect } from 'react'

import CharacterItem from '../../../Components/CharacterItem/CharacterItem'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import Spinner from '../../../Components/Spinner/Spinner'

import { getCharacters, deleteCharacter } from '../../../utils/utils'
import Confirmation from '../../../Components/Confirmation/Confirmation'

export default function Characters({ setModal, modal }) {
    const [characters, setCharacters] = useState([])
    const [formattedCharacters, setFormattedCharacters] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [characterToUpdate, setCharacterToUpdate] = useState({})
    const [modalType, setModalType] = useState('addChar')

    const openAddModal = () => {
        setModalType('addChar')
        setModal(true)
    }

    const openUpdateModal = (character) => {
        setModalType('updateChar')
        setModal(true)
        setCharacterToUpdate(character)
    }

    const closeModal = () => {
        setModal(false)
    }

    const fetchCharacters = async () => {
        setIsLoading(true)
        try {
            const { data } = await getCharacters()
            setCharacters(data)
            const formattedCharacters = data.map((char) => char.name)
            setFormattedCharacters(formattedCharacters)
        } catch (_) {
            setCharacters([])
        } finally {
            setIsLoading(false)
        }
    }

    const removeCharacter = async (characterToDelete) => {
        try {
            await deleteCharacter(characterToDelete)
            fetchCharacters()
        } catch (_) {
            throw new Error('Erreur')
        }
    }

    useEffect(() => {
        fetchCharacters()
    }, [])

    return (
        <>
            <div className="flex justify-between items-center">
                <h1 className="sm:ml-4">Mes Personnages</h1>
                <button
                    onClick={openAddModal}
                    className="w-52 py-2 border border-secondary bg-secondary text-secondary-text rounded-md">
                    Ajouter un personnage
                </button>
            </div>
            <div className="flex flex-wrap justify-between">
                {!isLoading ? (
                    characters.length > 0 ? (
                        characters.map((item, index) => (
                            <CharacterItem
                                key={`${item}-${index}`}
                                character={item}
                                removeCharacter={removeCharacter}
                                openUpdateModal={openUpdateModal}
                            />
                        ))
                    ) : (
                        <InfoMessage>
                            Vous n'avez pas encore ajouté de personnage à votre
                            compte. Ajoutez votre premier personnage grâce au
                            formulaire ci-dessus !
                        </InfoMessage>
                    )
                ) : (
                    <Spinner centered margin />
                )}
            </div>
            {modal && (
                <Confirmation
                    closeModal={closeModal}
                    source={modalType}
                    characters={formattedCharacters}
                    fetchCharacters={fetchCharacters}
                    characterToUpdate={characterToUpdate}
                />
            )}
        </>
    )
}
