import { createContext, useContext, useState } from 'react'

const RPContentContext = createContext()

export function RPContentWrapper({ children }) {
    const [RPContent, setRPContent] = useState()
    const [characterCount, setCharacterCount] = useState(0)
    const [wordCount, setWordCount] = useState(0)

    return (
        <RPContentContext.Provider
            value={{
                RPContent,
                setRPContent,
                characterCount,
                setCharacterCount,
                wordCount,
                setWordCount,
            }}>
            {children}
        </RPContentContext.Provider>
    )
}

export function useRPContent() {
    return useContext(RPContentContext)
}
