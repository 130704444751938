import { createContext, useContext, useState } from 'react'

const FullscreenContext = createContext()

export function FullscreenWrapper({ children }) {
    const [fullscreenIsOpen, setFullscreenIsOpen] = useState(false)

    return (
        <FullscreenContext.Provider
            value={{
                fullscreenIsOpen,
                setFullscreenIsOpen,
            }}>
            {children}
        </FullscreenContext.Provider>
    )
}

export function useFullscreen() {
    return useContext(FullscreenContext)
}
