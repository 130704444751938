import Spinner from '../Spinner/Spinner'

export default function EditorLoader() {
    return (
        <>
            <div className="flex items-center rounded-md bg-background mb-4 h-[62px]"></div>
            <div className="border flex border-solid border-border-color text-main rounded-md p-6 mb-2 h-[420px]">
                <Spinner />{' '}
                <p className="ml-4 mt-2">Chargement du brouillon...</p>
            </div>
        </>
    )
}
