import { useState, useEffect } from 'react'

import { getStats, getCharacters } from '../../../utils/utils'
import MenuItem from '../../../Components/MenuItem/MenuItem'
import StatItem from '../../../Components/StatItem/StatItem'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import Spinner from '../../../Components/Spinner/Spinner'

export default function Stats() {
    const [allRPs, setAllRPs] = useState([])
    const [characters, setCharacters] = useState([])
    const [charactersLoaded, setCharactersLoaded] = useState()
    const [isLoading, setIsLoading] = useState()
    const [activeTab, setActiveTab] = useState('Depuis le début')
    const [hasError, setHasError] = useState(false)
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    const fetchStats = async () => {
        setIsLoading(true)
        try {
            const { data } = await getStats()
            setAllRPs(data)
        } catch (_) {
            setAllRPs([])
            triggerError()
        } finally {
            setIsLoading(false)
        }
    }

    const fetchCharacters = async () => {
        try {
            const { data } = await getCharacters()
            const formattedCharacters = data.map((char) => char.name)
            setCharacters(formattedCharacters)
            setCharactersLoaded(true)
        } catch (_) {
            setCharacters([])
            triggerError()
            setCharactersLoaded(true)
        }
    }

    const triggerError = () => {
        setHasError(true)
    }

    useEffect(() => {
        fetchStats()
        fetchCharacters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let noCharRPs
    if (activeTab === 'Cette année') {
        noCharRPs = allRPs.filter(
            (el) =>
                el.characterName === '' &&
                parseInt(el.date.split('-')[0]) === currentYear
        )
    } else if (activeTab === 'Ce mois-ci') {
        noCharRPs = allRPs.filter(
            (el) =>
                el.characterName === '' &&
                parseInt(el.date.split('-')[1]) === currentMonth &&
                parseInt(el.date.split('-')[0]) === currentYear
        )
    } else if (activeTab === 'Depuis le début') {
        noCharRPs = allRPs.filter((el) => el.characterName === '')
    }
    const noCharWordCount = noCharRPs
        ? noCharRPs.reduce((acc, curVal) => Number(acc + curVal.wordCount), [])
        : null

    return (
        <>
            <h1 className="sm:ml-4">Mes Statistiques</h1>
            {hasError ? (
                <InfoMessage type="warning">
                    <p>Une erreur est survenue, veuillez ré-essayer.</p>
                </InfoMessage>
            ) : null}
            <div className="flex sm:flex-row flex-col justify-between">
                <section className="sm:mr-8 sm:w-1/4 sm:mb-0 mb-4">
                    <ul className="flex flex-col justify-between">
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="stats-category"
                            name="Depuis le début">
                            Depuis le début
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="stats-category"
                            name="Ce mois-ci">
                            Ce mois-ci
                        </MenuItem>
                        <MenuItem
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            testId="stats-category"
                            name="Cette année">
                            Cette année
                        </MenuItem>
                    </ul>
                </section>
                <section className="w-full">
                    {!isLoading && charactersLoaded ? (
                        characters.length > 0 ? (
                            characters.map((item, index) => {
                                let charRPs
                                if (activeTab === 'Cette année') {
                                    charRPs = allRPs.filter(
                                        (el) =>
                                            el.characterName.toLowerCase() ===
                                                item.toLowerCase() &&
                                            parseInt(el.date.split('-')[0]) ===
                                                currentYear
                                    )
                                } else if (activeTab === 'Ce mois-ci') {
                                    charRPs = allRPs.filter(
                                        (el) =>
                                            el.characterName.toLowerCase() ===
                                                item.toLowerCase() &&
                                            parseInt(el.date.split('-')[1]) ===
                                                currentMonth &&
                                            parseInt(el.date.split('-')[0]) ===
                                                currentYear
                                    )
                                } else if (activeTab === 'Depuis le début') {
                                    charRPs = allRPs.filter(
                                        (el) =>
                                            el.characterName.toLowerCase() ===
                                            item.toLowerCase()
                                    )
                                }
                                const totalWordCount = charRPs.reduce(
                                    (acc, curVal) =>
                                        Number(acc + curVal.wordCount),
                                    []
                                )
                                const totalRPs = charRPs.length
                                return (
                                    <StatItem
                                        words={totalWordCount}
                                        RPs={totalRPs}
                                        character={item}
                                        key={`${item}-${totalWordCount}`}
                                    />
                                )
                            })
                        ) : (
                            <InfoMessage infoStyle="mb-4">
                                Vous n'avez pas encore ajouté de personnage à
                                votre compte. Une fois votre premier RP écrit et
                                lié à un personnage, vous verrez vos
                                statistiques de RP par personnage ici.
                            </InfoMessage>
                        )
                    ) : (
                        <Spinner centered margin />
                    )}
                    {!isLoading && charactersLoaded && noCharWordCount > 0 ? (
                        <StatItem
                            words={noCharWordCount}
                            RPs={noCharRPs.length}
                            character="Aucun personnage"
                            key={`Aucun-${noCharWordCount}`}
                        />
                    ) : null}
                </section>
            </div>
        </>
    )
}
