export default function StatusSpinner() {
    return (
        <div data-testid="spinner">
            <div className="status-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
