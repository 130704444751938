import { useFullscreen } from '../../Context/fullscreen'

export default function FullScreen({ children }) {
    const { fullscreenIsOpen } = useFullscreen()
    if (fullscreenIsOpen) {
        return (
            <div className="absolute top-0 left-0 h-full w-full bg-main-background p-4 z-[1]">
                {children}
            </div>
        )
    } else {
        return children
    }
}
