import PrimaryButton from '../Buttons/PrimaryButton'
import Tooltip from '../Tooltip/Tooltip'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'
import InfoMessage from '../InfoMessage/InfoMessage'
import { addCharacter } from '../../utils/utils'
import { useState } from 'react'

export default function AddCharacter({ fetchCharacters, closeModal }) {
    const [postIsLoading, setPostIsLoading] = useState(false)
    const [newCharacterName, setNewCharacterName] = useState('')
    const [newCharacterAge, setCharacterAge] = useState('')
    const [newCharacterRpColor, setCharacterRpColor] = useState('')
    const [newCharacterOccupation, setCharacterOccupation] = useState('')
    const [newCharacterDescription, setCharacterDescription] = useState('')
    const [newCharacterPicture, setCharacterPicture] = useState('')
    const [hasError, setHasError] = useState(false)

    const triggerError = () => {
        setHasError(true)
    }

    const postCharacter = async () => {
        setPostIsLoading(true)
        try {
            await addCharacter({
                newCharacterName,
                newCharacterAge,
                newCharacterRpColor,
                newCharacterOccupation,
                newCharacterDescription,
                newCharacterPicture,
            })
            fetchCharacters()
        } catch (_) {
            triggerError()
        } finally {
            setPostIsLoading(false)
            setNewCharacterName('')
            closeModal()
        }
    }

    return (
        <div className="text-center mx-auto p-4 w-11/12">
            <div className="flex justify-center m-auto mt-6">
                <h3>Ajouter un personnage</h3>
                <Tooltip tooltipStyle="sm:mt-[3px] mt-0" position={null}>
                    Ajoutez vos personnages en indiquant leur pseudo exact sur
                    votre forum. Cela permettra de suivre le statut de vos RPs !
                </Tooltip>
            </div>
            {hasError && (
                <InfoMessage type="warning">
                    <p>
                        Une erreur est survenue au moment d'ajouter votre
                        personnage. Veuillez ré-essayer.
                    </p>
                </InfoMessage>
            )}
            <div className="my-6">
                <div className="sm:flex justify-between">
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="characterName" className="text-sm">
                            Nom du personnage
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterName"
                            name="Nom du personnage"
                            placeholder="Nom du personnage"
                            className="px-8 py-4 mb-4 w-full mt-2 rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) =>
                                setNewCharacterName(e.target.value)
                            }
                            value={newCharacterName}
                        />
                    </div>
                    <div className="text-left sm:w-1/2 sm:ml-2">
                        <label htmlFor="characterAge" className="text-sm">
                            Âge du personnage
                        </label>
                        <br />
                        <input
                            type="number"
                            id="characterAge"
                            name="Âge du personnage"
                            placeholder="Âge du personnage"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) => setCharacterAge(e.target.value)}
                            value={newCharacterAge}
                        />
                    </div>
                </div>
                <div className="sm:flex justify-between">
                    <div className="text-left sm:w-1/2 sm:mr-2">
                        <label htmlFor="characterRpColor" className="text-sm">
                            Couleur en RP
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterRpColor"
                            name="Couleur en RP"
                            placeholder="#006100"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) =>
                                setCharacterRpColor(e.target.value)
                            }
                            value={newCharacterRpColor}
                        />
                    </div>
                    <div className="text-left sm:w-1/2 sm:ml-2">
                        <label
                            htmlFor="characterOccupation"
                            className="text-sm">
                            Occupation / métier
                        </label>
                        <br />
                        <input
                            type="text"
                            id="characterOccupation"
                            name="Occupation"
                            placeholder="Occupation / métier"
                            className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                            onChange={(e) =>
                                setCharacterOccupation(e.target.value)
                            }
                            value={newCharacterOccupation}
                        />
                    </div>
                </div>
                <div className="text-left sm:w-full">
                    <label htmlFor="characterPicture" className="text-sm">
                        Avatar du personnage (200x320)
                    </label>
                    <br />
                    <input
                        type="text"
                        id="characterPicture"
                        name="Avatar"
                        placeholder="https://i.imgur.com/Tv6WFzv.png"
                        className="px-8 py-4 mb-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                        onChange={(e) => setCharacterPicture(e.target.value)}
                        value={newCharacterPicture}
                    />
                </div>
                <div className="text-left sm:w-full">
                    <label htmlFor="characterDescription" className="text-sm">
                        Description du personnage
                    </label>
                    <br />
                    <textarea
                        type="text"
                        id="characterDescription"
                        name="Description du personnage"
                        placeholder="Les infos importantes du personnage"
                        className="px-8 py-4 mt-2 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary h-32"
                        onChange={(e) =>
                            setCharacterDescription(e.target.value)
                        }
                        value={newCharacterDescription}
                    />
                </div>
            </div>

            <div className="flex justify-center pb-8">
                <PrimaryButton clickFunc={postCharacter}>
                    {postIsLoading ? (
                        <ButtonSpinner />
                    ) : (
                        'Ajouter le personnage'
                    )}
                </PrimaryButton>
            </div>
        </div>
    )
}
