import { useEffect, useState } from 'react'

import { checkRPs } from '../../utils/utils'
import StatusSpinner from '../StatusSpinner/StatusSpinner'
import EditLight from '../../Assets/edit-light.svg'
import EditDark from '../../Assets/edit-dark.svg'
import Trash from '../../Assets/trash-light.svg'
import TrashDark from '../../Assets/trash-dark.svg'
import Manual from '../../Assets/manual-light.svg'
import ManualDark from '../../Assets/manual-dark.svg'
import Auto from '../../Assets/auto-light.svg'
import AutoDark from '../../Assets/auto-dark.svg'
import classNames from 'classnames'

import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'

import './RPItem.css'

export default function RPItem({
    rp,
    characters,
    removeRP,
    statusFilters,
    characterFilters,
    openUpdateModal,
    setRpCount,
    setRpFinishedCount,
    hasCharacterFilter,
    hasStatusFilter,
    setCountByStatus,
}) {
    const { theme } = useTheme()
    const [status, setStatus] = useState()
    const [isLoading, setIsLoading] = useState(
        rp?.manualTracking ? false : true
    )

    const isClosed = rp?.isClosed

    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }

    const fetchStatus = async () => {
        try {
            const { data } = await checkRPs(rp.link, characters)
            setStatus(data.message)
            setIsLoading(false)
            setCountByStatus((rpCount) => [
                ...rpCount,
                {
                    name: rp.name,
                    link: rp.link,
                    status: data.message === true ? 'À jour' : 'Pas à jour',
                    character: rp.character,
                },
            ])
            setRpCount((rpCount) => [
                ...rpCount,
                {
                    name: rp.name,
                    link: rp.link,
                    status: data.message === true ? 'À jour' : 'Pas à jour',
                    character: rp.character,
                },
            ])
        } catch (_) {
            setStatus(false)
            setIsLoading(false)
            setRpCount((rpCount) => [
                ...rpCount,
                {
                    name: rp.name,
                    link: rp.link,
                    status: 'Pas à jour',
                    character: rp.character,
                },
            ])
            setCountByStatus((rpCount) => [
                ...rpCount,
                {
                    name: rp.name,
                    link: rp.link,
                    status: 'Pas à jour',
                    character: rp.character,
                },
            ])
        }
    }

    const showRP = () => {
        if (
            (hasCharacterFilter &&
                characterFilters.length === characters.length) ||
            !hasCharacterFilter
        ) {
            if (hasStatusFilter) {
                if (status) {
                    return statusFilters.includes('À jour')
                } else {
                    return statusFilters.includes('Pas à jour')
                }
            } else {
                return true
            }
        } else if (
            hasCharacterFilter &&
            characterFilters.length !== characters.length
        ) {
            if (hasStatusFilter) {
                if (status) {
                    return (
                        statusFilters.includes('À jour') &&
                        characterFilters.includes(rp.character)
                    )
                } else {
                    return (
                        statusFilters.includes('Pas à jour') &&
                        characterFilters.includes(rp.character)
                    )
                }
            } else {
                return characterFilters.includes(rp.character)
            }
        }
    }

    const deleteRP = () => {
        removeRP(rp.link)
    }

    const openModal = () => {
        openUpdateModal(rp)
    }

    useEffect(() => {
        if (!isClosed) {
            if (rp.manualTracking) {
                setStatus(rp.manualTrackingStatus === 'À jour' ? true : false)
                setIsLoading(false)
            } else {
                setCountByStatus([])
                fetchStatus()
            }
        } else {
            setIsLoading(false)
            setRpFinishedCount((rpFinishedCount) => [
                ...rpFinishedCount,
                {
                    name: rp.name,
                    link: rp.link,
                    status: 'closed',
                    isClosed: true,
                    character: rp.character,
                },
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        (showRP() || isLoading) && (
            <div
                className={classNames(
                    isClosed ? 'sm:w-[49%]' : null,
                    'bg-background text-main flex flex-col sm:flex-row sm:justify-between items-start sm:items-center rounded-md px-6 py-4 my-4'
                )}>
                <div className="flex flex-col sm:flex-row items-start sm:items-center w-4/5">
                    {isClosed ? (
                        <span className="w-80px text-sm text-center text-grey border border-solid border-grey py-1 bg-light-grey rounded-md">
                            Terminé
                        </span>
                    ) : (
                        <div className="flex">
                            <div
                                className={`h-8 w-8 mr-4 p-1 bg-main-background flex items-center justify-center rounded-full`}
                                alt="test">
                                <img
                                    src={
                                        rp.manualTracking
                                            ? lightThemes.includes(theme)
                                                ? ManualDark
                                                : Manual
                                            : lightThemes.includes(theme)
                                            ? AutoDark
                                            : Auto
                                    }
                                    className="h-4 w-4"
                                    alt="tracking type"
                                    title={
                                        rp.manualTracking
                                            ? 'RP suivi manuellement'
                                            : 'RP suivi automatiquement'
                                    }
                                />
                            </div>
                            {!isLoading ? (
                                <span
                                    className={`w-80px text-sm text-center ${
                                        status
                                            ? 'text-green border-green bg-light-green'
                                            : 'text-red border-red bg-light-red '
                                    } border border-solid py-1 rounded-md`}>
                                    {status ? 'À jour' : 'Pas à jour'}
                                </span>
                            ) : (
                                <span className="w-80px h-32px text-sm text-center text-grey border border-border-color border-solid py-1 rounded-md">
                                    <StatusSpinner />
                                </span>
                            )}
                        </div>
                    )}
                    <p
                        className={classNames(
                            `sm:mx-4 sm:mt-0 mt-4 py-1 px-2 text-sm rounded border border-solid border-border-color truncate ${
                                isClosed ? `w-32` : `w-40`
                            }`
                        )}>
                        {rp.character || 'Aucun personnage'}
                    </p>
                    <a
                        href={rp.link}
                        target="_blank"
                        rel="noreferrer"
                        className="sm:ml-4 sm:my-0 my-4 rp-item-link truncate text-sm">
                        {rp.name || rp.link}
                    </a>
                    {!isClosed && (
                        <p className="text-xs sm:mx-4 sm:mb-0 mb-4">
                            {rp.lastDate &&
                                new Date(rp.lastDate).toLocaleDateString(
                                    'fr-FR',
                                    dateOptions
                                )}
                        </p>
                    )}
                </div>
                <div className="flex">
                    <img
                        className="cursor-pointer edit-icon w-6 h-6 mr-4"
                        onClick={openModal}
                        src={lightThemes.includes(theme) ? EditLight : EditDark}
                        alt="modifier"
                    />
                    <img
                        className="cursor-pointer trash-icon w-6 h-6"
                        onClick={deleteRP}
                        src={lightThemes.includes(theme) ? Trash : TrashDark}
                        alt="supprimer"
                    />
                </div>
            </div>
        )
    )
}
