import { lightThemes } from '../../Data/const'

import { useTheme } from '../../Context/theme'
import Trash from '../../Assets/trash-light.svg'
import TrashDark from '../../Assets/trash-dark.svg'
import Edit from '../../Assets/edit-light.svg'
import EditDark from '../../Assets/edit-dark.svg'

import { isLightColor } from '../../utils/utils'

export default function CharacterItem({
    character,
    removeCharacter,
    openUpdateModal,
}) {
    const { theme } = useTheme()
    const deleteCharacter = () => {
        removeCharacter(character?.name)
    }
    const editCharacter = () => {
        openUpdateModal(character)
    }
    const defaultAvatar = 'https://i.imgur.com/hfXkZWE.png'
    return (
        <>
            <div className="sm:w-[49%] w-full h-[288px] bg-background text-main flex flex-row sm:justify-between items-start rounded-md p-4 my-4">
                <img
                    src={character.picture || defaultAvatar}
                    className="h-[256px] w-[160px] object-cover"
                    alt="avatar"
                />
                <div className="ml-4 h-[256px] overflow-auto w-full">
                    <div className="flex flex-col-reverse sm:flex-row sm:justify-between items-start sm:items-center w-full">
                        <p className="sm:mt-0 mt-4 py-1 px-2 text-sm rounded border border-solid border-border-color w-40 truncate">
                            {character.name}
                        </p>
                        <div className="flex">
                            <img
                                className="cursor-pointer edit-icon w-6 h-6 mr-4"
                                onClick={editCharacter}
                                src={
                                    lightThemes.includes(theme)
                                        ? Edit
                                        : EditDark
                                }
                                alt="modifier"
                            />
                            <img
                                className="cursor-pointer trash-icon w-6 h-6"
                                onClick={deleteCharacter}
                                src={
                                    lightThemes.includes(theme)
                                        ? Trash
                                        : TrashDark
                                }
                                alt="supprimer"
                            />
                        </div>
                    </div>
                    <p
                        target="_blank"
                        rel="noreferrer"
                        className="mt-2 my-4 py-1 px-2 text-sm rounded rounded-md w-fit rp-item-link truncate text-sm text-white"
                        style={{
                            backgroundColor: character.rpColor,
                            color: isLightColor(character.rpColor)
                                ? '#333'
                                : '#fff',
                        }}>
                        {character.rpColor}
                    </p>
                    {character.age && (
                        <p className="text-sm mt-2 mb-0">{character.age} ans</p>
                    )}
                    <p className="text-sm mt-2 mb-0">{character.occupation}</p>
                    <div className="w-full mt-1 text-sm font-light">
                        {character.description}
                    </div>
                </div>
            </div>
        </>
    )
}
