import { useCurrentEditor } from '@tiptap/react'
import React from 'react'
import { useState } from 'react'
import Compact from '@uiw/react-color-compact'
import { lightThemes } from '../../Data/const'

import underlineLight from '../../Assets/MenuBar/underline_light.svg'
import underlineDark from '../../Assets/MenuBar/underline_dark.svg'
import boldLight from '../../Assets/MenuBar/bold_light.svg'
import boldDark from '../../Assets/MenuBar/bold_dark.svg'
import italicLight from '../../Assets/MenuBar/italic_light.svg'
import italicDark from '../../Assets/MenuBar/italic_dark.svg'
import strikethroughLight from '../../Assets/MenuBar/strikethrough_light.svg'
import strikethroughDark from '../../Assets/MenuBar/strikethrough_dark.svg'
import clearLight from '../../Assets/MenuBar/clear_light.svg'
import clearDark from '../../Assets/MenuBar/clear_dark.svg'
import bulletLight from '../../Assets/MenuBar/bullet_light.svg'
import bulletDark from '../../Assets/MenuBar/bullet_dark.svg'
import numberedLight from '../../Assets/MenuBar/numbered_light.svg'
import numberedDark from '../../Assets/MenuBar/numbered_dark.svg'
import indentLight from '../../Assets/MenuBar/indent_light.svg'
import indentDark from '../../Assets/MenuBar/indent_dark.svg'
import lineLight from '../../Assets/MenuBar/line_light.svg'
import lineDark from '../../Assets/MenuBar/line_dark.svg'
import colorLight from '../../Assets/MenuBar/color_light.svg'
import colorDark from '../../Assets/MenuBar/color_dark.svg'
import fullscreenExitLight from '../../Assets/MenuBar/fullscreen_exit_light.svg'
import fullscreenExitDark from '../../Assets/MenuBar/fullscreen_exit_dark.svg'
import fullscreenOpenLight from '../../Assets/MenuBar/fullscreen_open_light.svg'
import fullscreenOpenDark from '../../Assets/MenuBar/fullscreen_open_dark.svg'

import { useTheme } from '../../Context/theme'
import { useFullscreen } from '../../Context/fullscreen'
import { useRPContent } from '../../Context/rpContent'

import './MenuBar.css'
import { removeSpaces } from '../../utils/utils'

const MenuBar = () => {
    const { theme } = useTheme()
    const { fullscreenIsOpen, setFullscreenIsOpen } = useFullscreen()
    const { setRPContent, RPContent } = useRPContent()
    const { editor } = useCurrentEditor()
    const [isColorOpen, setIsColorOpen] = useState(false)
    const [color, setColor] = useState('#561ecb')

    const openFullscreen = () => {
        if (fullscreenIsOpen === false) {
            window.scrollTo(0, 0)
        }
        setRPContent(removeSpaces(RPContent))
        setFullscreenIsOpen(true)
    }

    const closeFullscreen = () => {
        setRPContent(removeSpaces(RPContent))
        setFullscreenIsOpen(false)
    }

    if (!editor) {
        return null
    }

    const openColorPicker = () => {
        setIsColorOpen(!isColorOpen)
    }

    const changeColor = (color) => {
        setColor(color)
        editor.chain().focus().setColor(color.hex).run()
    }

    const colors = [
        '#e6b8af',
        '#f4cccc',
        '#fce5cd',
        '#fff2cc',
        '#d9ead3',
        '#d0e0e3',
        '#c9daf8',
        '#cfe2f3',
        '#d9d2e9',
        '#ead1dc',
        '#dd7e6b',
        '#ea9999',
        '#f9cb9c',
        '#ffe599',
        '#b6d7a8',
        '#a2c4c9',
        '#a4c2f4',
        '#9fc5e8',
        '#b4a7d6',
        '#d5a6bd',
        '#cc4125',
        '#e06666',
        '#f6b26b',
        '#ffd966',
        '#93c47d',
        '#76a5af',
        '#6d9eeb',
        '#6fa8dc',
        '#8e7cc3',
        '#c27ba0',
        '#a61c00',
        '#cc0000',
        '#e69138',
        '#f1c232',
        '#6aa84f',
        '#45818e',
        '#3c78d8',
        '#3d85c6',
        '#674ea7',
        '#a64d79',
        '#85200c',
        '#990000',
        '#b45f06',
        '#bf9000',
        '#38761d',
        '#134f5c',
        '#1155cc',
        '#0b5394',
        '#351c75',
        '#741b47',
        '#5b0f00',
        '#660000',
        '#783f04',
        '#7f6000',
        '#274e13',
        '#0c343d',
        '#1c4587',
        '#073763',
        '#20124d',
        '#4c1130',
    ]

    return (
        <div className="flex items-center p-4 rounded-md bg-background mb-4">
            <div
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                className={`editor-button hover:bg-main-background ${
                    editor.isActive('bold')
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={lightThemes.includes(theme) ? boldLight : boldDark}
                    alt="bold"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                className={`editor-button hover:bg-main-background ${
                    editor.isActive('italic')
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={lightThemes.includes(theme) ? italicLight : italicDark}
                    alt="italic"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={!editor.can().chain().focus().toggleUnderline().run()}
                className={`editor-button hover:bg-main-background ${
                    editor.isActive('underline')
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={
                        lightThemes.includes(theme)
                            ? underlineLight
                            : underlineDark
                    }
                    alt="underline"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={!editor.can().chain().focus().toggleStrike().run()}
                className={`editor-button hover:bg-main-background ${
                    editor.isActive('strike')
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={
                        lightThemes.includes(theme)
                            ? strikethroughLight
                            : strikethroughDark
                    }
                    alt="strikethrough"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().unsetAllMarks().run()}
                className="editor-button hover:bg-main-background">
                <img
                    src={lightThemes.includes(theme) ? clearLight : clearDark}
                    alt="clear"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={`editor-button hover:bg-main-background ${
                    editor.isActive('bulletList')
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={lightThemes.includes(theme) ? bulletLight : bulletDark}
                    alt="bullet"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={`editor-button hover:bg-main-background ${
                    editor.isActive('orderedList')
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={
                        lightThemes.includes(theme)
                            ? numberedLight
                            : numberedDark
                    }
                    alt="numbered"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={`editor-button hover:bg-main-background ${
                    editor.isActive('blockquote')
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={lightThemes.includes(theme) ? indentLight : indentDark}
                    alt="indent"
                />
            </div>
            <div
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
                className="editor-button hover:bg-main-background">
                <img
                    src={lightThemes.includes(theme) ? lineLight : lineDark}
                    alt="line"
                />
            </div>
            <div
                onClick={openColorPicker}
                className={`editor-button hover:bg-main-background relative ${
                    !!editor.getAttributes('textStyle').color
                        ? `is-active bg-main-background`
                        : ``
                }`}>
                <img
                    src={lightThemes.includes(theme) ? colorLight : colorDark}
                    alt="color"
                />
            </div>
            <div className="relative">
                {isColorOpen && (
                    <div className="absolute sm:top-[-15px] sm:left-2 right-[50px] top-8 z-[1]">
                        <Compact
                            color={color}
                            colors={colors}
                            onChange={(color) => changeColor(color)}
                        />
                    </div>
                )}
            </div>
            {fullscreenIsOpen ? (
                <div
                    onClick={closeFullscreen}
                    className="editor-button hover:bg-main-background">
                    <img
                        src={
                            lightThemes.includes(theme)
                                ? fullscreenExitLight
                                : fullscreenExitDark
                        }
                        alt="fermer plein écran"
                    />
                </div>
            ) : (
                <div
                    onClick={openFullscreen}
                    className="editor-button hover:bg-main-background">
                    <img
                        src={
                            lightThemes.includes(theme)
                                ? fullscreenOpenLight
                                : fullscreenOpenDark
                        }
                        alt="ouvrir plein écran"
                    />
                </div>
            )}
        </div>
    )
}

export default MenuBar
