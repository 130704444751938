import RPToOpenItem from '../RPToOpenItem/RPToOpenItem'
import InfoMessage from '../InfoMessage/InfoMessage'

import '../RPItem/RPItem.css'

export default function RPToOpen({ allRPs, removeRP }) {
    return (
        <div className="sm:flex sm:flex-wrap sm:justify-between">
            {allRPs?.length > 0 ? (
                allRPs.map((item) => (
                    <RPToOpenItem
                        key={`${item.name}-${item.character}`}
                        rp={item}
                        removeRP={removeRP}
                    />
                ))
            ) : (
                <InfoMessage>
                    Vous n'avez pas encore ajouté de RP à ouvrir. Ajoutez un RP
                    que vous souhaitez ouvrir via le bouton ci-dessus.
                </InfoMessage>
            )}
        </div>
    )
}
